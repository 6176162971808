import { flexSpace, linkHover } from '@/layout/styles/classes';
import { h3 } from '@/layout/styles/Typography';
import styled from '@emotion/styled';
import { AccordionButton, AccordionItem as Item, useAccordionItemContext } from '@reach/accordion';
import '@reach/accordion/styles.css';
import AnimateHeight from 'react-animate-height';

const AccordionItem = styled(Item)`
    margin: 0 0 16px;
    padding-bottom: 16px;
    border: none;

    :last-of-type {
        margin-bottom: 0;
    }

    > h3 {
        margin: 0;

        > button {
            font-weight: 700;
            font-family: 'Montserrat', sans-serif;
            text-transform: uppercase;
        }
    }

    p {
        margin: 16px 0 0;
        text-align: left;
        transform: translateY(-32px);
        opacity: 0;
        transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
    }

    a {
        ${linkHover};
    }

    &[data-state='open'] {
        p {
            transform: translateY(0);
            opacity: 1;
        }
    }
`;

const Question = styled(AccordionButton)`
    ${flexSpace};
    ${h3};
    padding: 0;
    text-align: left;
    color: ${({ theme }) => theme.colors.white};
    width: 100%;
    border: none;
    outline: none;
    background-color: transparent;
    transition: color 0.3s ease-in-out;

    ::after {
        content: '+';
        margin-left: 24px;
        font-size: 1.5rem;
        font-weight: 400;
        line-height: 100%;
        color: ${({ theme }) => theme.colors.white};
        transition: color 0.3s ease-in-out;
    }

    :hover,
    :focus-visible {
        cursor: pointer;
        color: ${({ theme }) => theme.colors.accent};

        ::after {
            color: ${({ theme }) => theme.colors.accent};
        }
    }

    &[data-state='open'] {
        ::after {
            content: '-';
            font-size: 2.2rem;
        }
    }
`;

type AccordionItemProps = {
    question?: string;
    children: React.ReactNode;
};

const AnimatedPanel = ({ children }: AccordionItemProps) => {
    const { isExpanded } = useAccordionItemContext();

    return (
        <AnimateHeight duration={300} easing="ease-in-out" height={isExpanded ? 'auto' : 0}>
            {children}
        </AnimateHeight>
    );
};

export const SingleQuestion = ({ question, children }: AccordionItemProps) => {
    return (
        <AccordionItem>
            <h3>
                <Question>{question}</Question>
            </h3>

            <AnimatedPanel>{children}</AnimatedPanel>
        </AccordionItem>
    );
};
