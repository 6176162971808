import { PrimaryBtn } from '@/buttons';
import CLink from '@/CLink';
import { getNavLink } from '@/layout/NavLinks';
import { flexCenter } from '@/layout/styles/classes';
import styled from '@emotion/styled';
import { Accordion } from '@reach/accordion';
import { whiteUnderline } from './layout/styles/Typography';

const StyledFaq = styled.section`
    padding: 32px 16px;
    color: ${({ theme }) => theme.colors.white};
    background-image: ${({ theme }) => theme.colors.gradient};

    @media (min-width: 768px) {
        padding: 32px;
    }

    @media (min-width: 1024px) {
        ${flexCenter};
        padding: 64px;
    }

    @media (min-width: 1280px) {
        padding-top: 128px;
        padding-bottom: 128px;
    }
`;

const Info = styled.div`
    margin-bottom: 32px;

    > h2 {
        ${whiteUnderline};
        margin: 0;
        font-weight: 700;
    }

    > p {
        margin: 32px 0;
    }

    @media (min-width: 1024px) {
        margin-bottom: 0;
        margin-right: ${({ theme }) => theme.spacing.lg};
        min-width: 300px;
        max-width: 540px;
    }
`;

const StyledAccordion = styled(Accordion)`
    @media (min-width: 1024px) {
        max-width: 650px;
    }
`;

type FaqProps = {
    children: React.ReactNode;
    service?: boolean;
};

export const Faq = ({ children }: FaqProps) => {
    return (
        <StyledFaq>
            <Info>
                <h2>Frequently Asked Questions</h2>
                <p>Have Questions About Preventive Dentistry? Find Answers Here.</p>
                <PrimaryBtn as={CLink} to={getNavLink('contact us')}>
                    Contact Us
                </PrimaryBtn>
            </Info>
            <StyledAccordion>{children}</StyledAccordion>
        </StyledFaq>
    );
};
