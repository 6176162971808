import { Article } from '@/Article';
import CLink from '@/CLink';
import { CommonHero } from '@/CommonHero';
import { Faq } from '@/Faq';
import { IA, ImageWrapper } from '@/ImageArticleBorder';
import { info } from '@/layout/NavLinks';
import { Seo } from '@/layout/Seo';
import { SingleQuestion } from '@/SingleQuestion';
import { Statement } from '@/Statement';
import { MDXProvider } from '@mdx-js/react';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage, IGatsbyImageData, ImageDataLike } from 'gatsby-plugin-image';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { Fragment } from 'react';

export const Phone = ({ text }: { text: string }) => (
    <CLink to={info.phone.link}>{text || info.phone.text}</CLink>
);

const shortCodes = {
    Article,
    IA,
    ImageWrapper,
    Phone,
    GatsbyImage,
    Faq,
    SingleQuestion,
};

type ServiceProps = {
    data: {
        mdx: {
            body: string & React.ReactNode;
            frontmatter: {
                cover: {
                    og: {
                        gatsbyImageData: IGatsbyImageData;
                    };
                    hero: ImageDataLike;
                };
                images: IGatsbyImageData[];
                statement: string[];
            };
        };
    };
    pageContext: {
        slug: string;
    };
};

export default function Service({ data, pageContext }: ServiceProps) {
    const {
        mdx: {
            frontmatter: { cover, images, statement },
            body,
        },
    } = data;

    const inlineImages = images && images.map(img => getImage(img));

    const slugArray = pageContext.slug.split('/');
    const service = slugArray[slugArray.length - 2].replace(/-/g, ' ');

    console.log(cover);
    return (
        <Fragment>
            {cover && <Seo image={cover.og.gatsbyImageData} />}
            <CommonHero noCover={!cover}>
                {cover && (
                    <GatsbyImage
                        image={getImage(cover.hero) as IGatsbyImageData}
                        alt={service}
                        loading="eager"
                    />
                )}
            </CommonHero>
            <Statement>
                {statement ? statement.map((text, i) => <p key={i}>{text}</p>) : null}
            </Statement>
            <MDXProvider components={shortCodes}>
                <MDXRenderer images={inlineImages}>{body}</MDXRenderer>
            </MDXProvider>
        </Fragment>
    );
}

export const query = graphql`
    query ($slug: String!) {
        mdx(fileAbsolutePath: { regex: $slug }) {
            frontmatter {
                cover {
                    og: childImageSharp {
                        gatsbyImageData(quality: 100, width: 1200, height: 630)
                    }
                    hero: childImageSharp {
                        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
                    }
                }
                images {
                    childImageSharp {
                        gatsbyImageData(width: 528, height: 720)
                    }
                }
                statement
            }
            body
        }
    }
`;
